<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="委託契約書CSV取込状況" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <div class="l-flex cols l-item">
          <div class="c-csvList-status">
            全体ステータス：<span class="blue">{{ totalStatus() }}</span>
          </div>
        </div>
      </div>
      <div class="l-container">
        <div class="l-box">
          <div class="c-csvList__wrap scroll">
            <table class="c-csvList">
              <tbody>
                <tr class="c-csvList__row head">
                  <th class="c-csvList__cell u-text-center w-240">状態</th>
                  <th class="c-csvList__cell w-50p">ファイル名</th>
                  <th class="c-csvList__cell u-text-center w-175">担当者</th>
                  <th class="c-csvList__cell u-text-center w-80">処理件数</th>
                  <th class="c-csvList__cell u-text-center w-150">
                    ファイル登録日
                  </th>
                  <th class="c-csvList__cell u-text-center w-150">
                    処理開始日
                  </th>
                  <th class="c-csvList__cell u-text-center w-150">
                    処理終了日
                  </th>
                </tr>
                <template v-for="(item, index) in registerCsv">
                  <tr
                    class="c-csvList__row list"
                    :key="index"
                    v-bind:class="[
                      { isError: item.entrustRegistProcessType > entrustRegistProcessTypeDomain.COMPLETE },
                      { isProcessing: item.entrustRegistProcessType == entrustRegistProcessTypeDomain.PROCESSING },
                    ]"
                  >
                    <td class="c-csvList__cell u-text-center">
                      <img v-if="item.entrustRegistProcessType > entrustRegistProcessTypeDomain.COMPLETE || item.entrustRegistProcessType == entrustRegistProcessTypeDomain.PROCESSING" v-bind:src="generateIconPath(item)" v-bind:class="generateClass(item)"/>
                      <p>{{statusName(item)}}</p>
                      <div v-if="item.entrustRegistProcessType > entrustRegistProcessTypeDomain.PROCESSING" class="c-csvList__popup">
                        <span>{{ item.errorInfo }}</span>
                      </div>
                    </td>
                    <td class="c-csvList__cell">{{ item.fileName }}</td>
                    <td class="c-csvList__cell u-text-center">
                      {{ item.registMember.name }}
                    </td>
                    <td class="c-csvList__cell u-text-center">
                      {{ item.rowNumber }}件
                    </td>
                    <td class="c-csvList__cell u-text-center">
                      {{ formatDateTime(item.createDate) }}
                    </td>
                    <td class="c-csvList__cell u-text-center">
                      {{ formatDateTime(item.startTime) }}
                    </td>
                    <td class="c-csvList__cell u-text-center">
                      {{ formatDateTime(item.endTime) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import {API_URL, DOMAIN} from "@/const";
import moment from "moment";

export default {
  name: "entrust-confirm",
  props: {
    onClickUploadButton: {
      type: Function,
    },
  },

  data() {
    return {
      menu: this.$store.getters["menu/get"],
      user: this.$store.getters["userInfo/get"],
      registerCsv: null,
      isError: "isError",
      isProcessing: "isProcessing",
      isErrorIcon: "/v_resources/assets/img/icon/info_red.svg",
      isProcessingIcon: "isProcessingIcon",
      entrustRegistProcessTypeDomain: DOMAIN.ENTRUST_REGIST_PROCESS_TYPE,
    };
  },

  created() {
    this.loadHistoryData();
  },

  methods: {
    loadHistoryData() {
      axios
        .get(API_URL.ENTRUST.CSV_CONFIRM)
        .then((res) => {
          this.registerCsv = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatDateTime (dateTime) {
      if (!dateTime) return "";
      const momentDateTime = moment(dateTime);
      return momentDateTime.format('YYYY/MM/DD HH:mm:ss');
    },

    statusName(item) {
      let status = "";

      if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.UNPROCESSED) {
        status = "未処理";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.PROCESSING) {
        status = "処理中";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.COMPLETE) {
        status = "完了";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.REGIST_ERROR) {
        status = "登録エラー";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.SEND_MAIL_ERROR) {
        status = "メール送信エラー";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.PDF_CREATE_ERROR) {
        status = "受渡確認票作成エラー";
      } else if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.BATCH_ERROR) {
        status = "バッチ共通エラー";
      }
      return status;
    },

    generateIconPath(item) {
      let iconPath = "";

      if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.PROCESSING) {
        iconPath = "/v_resources/assets/img/icon/processing.svg";
      } else if (item.entrustRegistProcessType > this.entrustRegistProcessTypeDomain.COMPLETE) {
        iconPath = "/v_resources/assets/img/icon/info_red.svg";
      }
      return iconPath;
    },

    generateClass(item) {
      let iconClass = "";

      if (item.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.PROCESSING) {
        iconClass = "width: 8px";
      } else if (item.entrustRegistProcessType > this.entrustRegistProcessTypeDomain.COMPLETE) {
        iconClass = "width: 16px";
      }
      return iconClass;
    },

    totalStatus() {
      if (!this.registerCsv) return "待機中";
      const countProcessing = this.registerCsv.filter((v) => v.entrustRegistProcessType == this.entrustRegistProcessTypeDomain.PROCESSING).length;
      if (countProcessing > 0) {
        return "処理中";
      }
      return "待機中";
    },
  },
};
</script>
<style scoped>
th {
  font-weight: 400;
}

.c-csvList__cell img {
  margin-right: 5px;
}

.c-csvList-status {
  font-size: 16px;
}

.c-csvList-status span {
  font-weight: 700;
  color: #124fb3;
}

td:first-child{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 16px;
}

.isError td:first-child {
  color: #ff6b6b;
  font-weight: 700;
}

.isProcessing td:first-child {
  color: #124fb3;
  font-weight: 700;
}

.isError td img {
  vertical-align: text-bottom;
}

.isError {
  background-color: #ffe0e0;
  position: relative;
}
.isProcessing {
  background-color: #e0ecfe;
  position: relative;
}

.l-flex {
  gap: 20px;
}

.errorList {
  display: inline-block;
  margin-right: 10px;
}

.errorList:first-child {
  margin-left: 10px;
}

.errorList:not(:last-child)::after {
  content: "、";
  display: inline-block;
  position: absolute;
  width: 1rem;
}

.c-csvList__popup {
  position: absolute;
  top: -3.5rem;
  left: 5px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 30px -6px rgba(51, 71, 102, 0.4);
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  padding: 0.8rem;
  z-index: 5000;
  color: #334766;
  font-size: 14px;
  font-weight: 400;
}

.c-csvList__popup:before {
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  border-top: 11px solid #fff;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  top: 100%;
  left: 15px;
}

.c-csvList__cell:hover .c-csvList__popup {
  opacity: 1;
  visibility: visible;
}

.u-text-center {
  text-align: center !important;
}

.w-80 {
  width: 80px;
}
.w-240 {
  width: 240px;
}

.w-150 {
  width: 150px;
}

.w-175 {
  width: 175px;
}

.c-csvList {
  table-layout: fixed;
  width: 100%;
}

.w-50p {
  width: 50%;
}

</style>
